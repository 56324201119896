import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import DigitalSignatureModal from "../digital_signature/digitalSignatureModal";
import { Col } from "react-flexbox-grid";

const TinySignature = props => {
  const [text, setText] = useState('');

  useEffect(() => {
    if (props.state[props.el.name]) {
      let field = props.state[props.el.name];
      if (typeof field === 'string') {
        //EXECUTING PROCEDURE
        setText(field);
      } else {
        //CONTINUE PROCEDURE
        if (typeof field['value'] === 'object') {
          setText(field['value']['value']);
        } else {
          setText(field['value']);
        }
      }
    } else {
      setText(props.el.extra_attributes.document_information.data);
    }
  }, [props.el.id]);

  const handleEditorChange = e => {
    if (props.el.extra_attributes.signature) {
      const url = props.el.extra_attributes.document_information.signature ?
        props.el.extra_attributes.document_information.signature : "";
      setText(e.target.getContent());
      props.onChangeHandler(
        {
          url: url,
          value: e.target.getContent()
        },
        props.el.name,
        props.el.type
      );
      props.el.extra_attributes.document_information.data = e.target.getContent();
    } else {
      setText(e.target.getContent());
      props.onChangeHandler(
        e.target.getContent(),
        props.el.name,
        props.el.type
      );
      props.el.extra_attributes.document_information.data = e.target.getContent();
    }
  };

  return (
    <Col xs={12} className="p-10" style={{ minHeight: "1250px" }}>

        <Col className="p-5" xs={12}>
            <DigitalSignatureModal {...props} htmlIntoBase64={text} />
        </Col>

        {
            text !== "" ?
            <Editor
                id={String(props.el.id)}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                value={text}
                init={{
                plugins: "link image code",
                toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                language: "es_MX",
                language_url: "/langs/es_MX.js"
                }}
                onChange={handleEditorChange}
            /> : ""
        }
    </Col>
  );
};

export default TinySignature;
