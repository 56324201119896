import { Request } from "../../classes/Request";
import Cookies from "js-cookie";

/**********************************************************SAVE AVATAR************************************************************************/
export const getDataSuggest = async (url, searchText) => {
  const dataSuggestRequest = new Request(
    `${url}&token=${process.env.REACT_APP_TOKEN}&query=${searchText}`,
    "get",
    null
  );

  const dataSuggestResponse = await dataSuggestRequest.executeRequest();
  return dataSuggestResponse;
};
/***********************************************************************************************************************************************/

export const getIsFieldDisabled = (el, applicant, mode) => {
  const { userId_02 } = Cookies.get();
  const userId = Number(userId_02);
  const applicantId = Number(applicant);
  const isAReadonlyElement = el.readonly === 1;
  const isAMode1 = mode === 1;
  const isAMode2 = mode === 2;

  if (isAMode2) {
    return true;
  }

  if (!isAReadonlyElement && isAMode1) {
    return false;
  }

  if (isAReadonlyElement && isAMode1) {
    return true;
  }

  if (userId !== applicantId) {
    return true;
  }

  if (el.observations) {
    return false;
  }

  return true;
};
