import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Col } from "react-flexbox-grid";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";

const TinyEditor = props => {
  const [text, setText] = useState('');
  const editorRef = useRef(null);

  useEffect(() => {
    if (props.state[props.el.name]) {
      let field = props.state[props.el.name];
      if (typeof field === 'string') {
        //EXECUTING PROCEDURE
        setText(field);
      } else {
        //CONTINUE PROCEDURE
        if (typeof field['value'] === 'object') {
          setText(field['value']['value']);
        } else {
          setText(field['value']);
        }
      }
    } else {
      setText(props.el.extra_attributes.document_information.data);
    }
  }, [props.el.id]);

  const handleEditorChange = e => {
    if (props.el.extra_attributes.signature) {
      const url = props.el.extra_attributes.document_information.signature ?
        props.el.extra_attributes.document_information.signature : "";
      setText(e.content);
      props.onChangeHandler(
        {
          url: url,
          value: e.content
        },
        props.el.name,
        props.el.type
      );
      props.el.extra_attributes.document_information.data = e.content;
    } else {
      setText(e.content);
      props.onChangeHandler(
        e.content,
        props.el.name,
        props.el.type
      );
      props.el.extra_attributes.document_information.data = e.content;
    }
  };

  const getContentText = () => {
    if (editorRef.current) {
      setText(editorRef.current.getContent());
      return editorRef.current.getContent();
    }
  };

  const downloadDocument = async () => {
    const { authentication_token_03 } = Cookies.get();
    const { el, stage } = props;
    const { extra_attributes, id, name } = el;
    const url = `${process.env.REACT_APP_URL_API_LARAVEL}/documents/get_pdf_for_detail/${stage}/${extra_attributes['document_id']}`;
    let data = new FormData();
    data.append('field_id', id);
    data.append('content_file', getContentText());

    await fetch(url, { 
      method:'POST',
      body: data,
      headers: {
        Authorization: authentication_token_03
      } 
    })
      .then(response => 
        response.blob()
        .then(blob => {
          if (blob && blob['type'] && blob['type'] === 'application/pdf') {
            let a = document.createElement('a');
            let url = URL.createObjectURL(blob);
            a.href = url;
            a.download = name + '.pdf';
            a.click(); 
            setTimeout(() => URL.revokeObjectURL(url), 500);
          } else NotificationManager.error(
            'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
            '¡Que mal!',
            5000
          );
        })
      .catch(err => NotificationManager.error(
        'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
        '¡Que mal!',
        5000
      )));
  };

  return (
    <Col xs={12} className="p-10" style={{ minHeight: "1250px" }}>
        <Button
          onClick={() => downloadDocument()}
          className='mB-10' 
          variant='success'
        >
          Descargar
        </Button>
        <Editor
          onInit={(evt, editor) => editorRef.current = editor}
          id={String(props.el.id)}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          value={text}
          init={{
            plugins: "link image code table",
            toolbar:
              "undo redo | bold italic | alignleft aligncenter alignright | code | table",
            language: "es_MX",
            language_url: "/langs/es_MX.js"
          }}
          onGetContent={handleEditorChange}
        />
    </Col>
  );
};

export default TinyEditor;
